import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/src/tagify.scss";

const dropdown = {
  enabled: 1,
  position: "text",
  closeOnSelect: true,
  highlightFirst: true,
  maxItems: Infinity,
};

// Convert default syntax [{ value: 'foo' }, { value: 'bar' }] to foo,bar
function originalInputValueFormat(tags) {
  return tags.map((tag) => tag.value).join(",");
}

export function tagify(selector = "[data-tagify]") {
  const elements = document.querySelectorAll(selector);
  const enforceWhitelist = true;

  return [...elements].map((el) => {
    let whitelist = el.getAttribute("data-tagify");

    if (whitelist) {
      whitelist = whitelist.split(",");
    }

    return new Tagify(el, {
      dropdown,
      enforceWhitelist,
      originalInputValueFormat,
      whitelist,
    });
  });
}
