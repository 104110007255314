import $ from "jquery";

export function initBusinessOwnershipRadios() {
  if ($(".business-ownership input:radio").length) {
    setCookie("registration_type", "user_business");
    setCookie("ownership_type", "owner");
  }

  $(".business-ownership input:radio").change(function () {
    var key = $(this).attr("name");
    var value = $(this).attr("value");

    setCookie(key, value);
    checkCookies();
  });
}

function checkCookies() {
  deselect();

  if (
    getCookie("registration_type") === "administrator_business" ||
    getCookie("registration_type") === "client_business" ||
    getCookie("ownership_type") === "non-owner"
  ) {
    select();
  }
}

function deselect() {
  $("#facebook-login").removeClass("d-none");
  $("#email-login").addClass("pl-sm-4 col-sm-6").removeClass("col-sm-12");
}

function select(element) {
  $("#facebook-login").addClass("d-none");
  $("#email-login").addClass("col-sm-12").removeClass("pl-sm-4 col-sm-6");
}

function setCookie(name, value, minutes = 10, path = "/") {
  const expires = new Date(Date.now() + minutes * 60 * 1000).toUTCString();

  document.cookie =
    name +
    "=" +
    encodeURIComponent(value) +
    "; expires=" +
    expires +
    "; path=" +
    path;
}

function getCookie(key) {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === key ? decodeURIComponent(parts[1]) : r;
  }, "");
}
