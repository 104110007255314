// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../src/fontawesome";
import "../src/images";
import "../src/rails";

import { tagify } from "../src/tagify";
import { typeahead } from "../src/typeahead";
import { initPaymentCards } from "../src/payment";
import { initBusinessOwnershipRadios } from "../src/sign-up";
import {
  customFileInputValue,
  dataOnload,
  handleBusinessAdministrator,
  handleRememberMe,
  initTabs,
  initTooltips,
  removeIsValid,
  truncateToggle,
} from "../src/bootstrap";

import "../stylesheets/application";

function onPageLoad() {
  customFileInputValue();
  dataOnload();
  handleBusinessAdministrator();
  handleRememberMe();
  initBusinessOwnershipRadios();
  initPaymentCards();
  initTabs();
  initTooltips();
  removeIsValid();
  tagify();
  truncateToggle();
  typeahead();
}

// Modules to reload on a page change
document.addEventListener("turbolinks:load", onPageLoad);
