import "typeahead.js/dist/typeahead.jquery.js";
import "../stylesheets/typeahead";

function onSelect(event, suggestion) {
  const inputs = $(this).data("typeaheadSelect");
  if (!inputs) return;

  const setInputValue = (key) => {
    const $el = $(inputs[key]);
    const value = suggestion[key];

    $el.val(value);
    $el.prop("disabled", !value);
  };

  Object.keys(inputs).forEach(setInputValue);
}

export function typeahead() {
  const options = {
    minLength: 2,
    highlight: true,
    classNames: {
      dataset: "text-left",
    },
  };

  const search_url = (type) => {
    switch (type) {
      case "suburbs":
        return "/suburbs";
      case "businesses":
        return "/search/businesses_by_name";
    }
  };

  const dataset = (type) => {
    return {
      name: type,
      displayKey: "name",
      source: (q, syncResults, asyncResults) => {
        return $.get(search_url(type), { q }, asyncResults);
      },
      templates: {
        suggestion: ({ name, postcode, id }) => {
          return type === "suburbs"
            ? `<span>${name}, ${postcode}</span>`
            : `<a href="/businesses/${id}"><span>${name}</span></a>`;
        },
      },
    };
  };

  const businesses = $("[data-typeahead='businesses']");
  const suburbs = $("[data-typeahead='suburbs']");

  if (businesses.length > 0) {
    businesses.typeahead(options, dataset("businesses"));
  } else if (suburbs.length > 0) {
    suburbs
      .typeahead(options, dataset("suburbs"))
      .bind("typeahead:select", onSelect);
  }
}
