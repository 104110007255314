// Load just the icons required
// https://fontawesome.com/how-to-use/on-the-web/advanced/svg-javascript-core

import { library, dom } from "@fortawesome/fontawesome-svg-core";

import {
  faBars,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faEnvelope,
  faGlobe,
  faMapMarker,
  faMapMarkerAlt,
  faPhoneAlt,
  faStoreAlt,
  faThumbsUp,
  faTrash,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

library.add(
  faBars,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faEnvelope,
  faFacebook,
  faGlobe,
  faMapMarker,
  faMapMarkerAlt,
  faPhoneAlt,
  faStoreAlt,
  faThumbsUp,
  faTrash,
  faTwitter,
  faUserCircle
);

// Replace any existing <i> tags with <svg> and set up a MutationObserver to
// continue doing this as the DOM changes.
dom.watch();
