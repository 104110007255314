import $ from "jquery";

export function initPaymentCards() {
  $(".subscription input:radio").change(function () {
    var radioClicked = $(this).attr("id");
    deselect();
    clickRadio(radioClicked);
    select(radioClicked);
  });

  $(".card-payment").click(function () {
    var inputElement = $(this).find("input[type=radio]").attr("id");
    deselect();
    select(inputElement);
    clickRadio(inputElement);
  });
}

function clickRadio(inputElement) {
  $("#" + inputElement).prop("checked", true);
}

function deselect() {
  $(".subscription input:radio").prop("checked", false);
  $(".card").removeClass("border-blue-200");
  $(".payment-option").addClass("d-none");
}

function select(element) {
  $(`#${element}-card`).addClass("border-blue-200");
  $(`#option-${element}`).removeClass("d-none");
}
