import $ from "jquery";
import "bootstrap/scss/bootstrap";
import "bootstrap";

export function customFileInputValue() {
  const $inputs = $(".custom-file-input");

  function setLabel($el, value) {
    if ($el && value) {
      $el.next(".custom-file-label").html(value);
    }
  }

  if ($inputs.length > 0) {
    $inputs.each(function () {
      const $el = $(this);
      setLabel($el, $el.attr("placeholder"));
    });

    $inputs.on("change", function () {
      const $el = $(this);
      setLabel($el, this.files[0].name);
    });
  }
}

export function dataOnload() {
  const $el = $("[data-onload]");

  if ($el.hasClass("modal")) {
    $el.modal($el.data("onload"));
  }
}

export function handleBusinessAdministrator(e) {
  $("#business-administrator-checkbox").on("click", (e) => {
    $("#business-administrator-form").toggleClass("d-none");
  });
}

export function handleRememberMe(e) {
  $("#remember-me-checkbox").on("click", (e) => {
    document.cookie = `remember_me=${e.target.checked}`;
  });
}

export function initTabs() {
  $("#mainTab a").on("click", function (event) {
    event.preventDefault();
    $(this).tab("show");
  });

  $("#mainTab a").first().click();
}

export function initTooltips() {
  $('[data-toggle="tooltip"]').tooltip();
}

export function removeIsValid() {
  // Only remove is-valid when form has no invalid fields
  if (document.getElementsByClassName("is-invalid").length > 0) {
    return;
  }

  const elements = document.getElementsByClassName("is-valid");
  [...elements].map((el) => el.classList.remove("is-valid"));
}

export function truncateToggle() {
  $('button[data-toggle="truncate"]').each(function () {
    const $el = $(this);
    const $target = $($el.data("truncateTarget"));
    const minWidth = $el.data("truncateMinWidth");
    const maxWidth = $el.data("truncateMaxWidth");

    function show() {
      $target.addClass("text-truncate");
      if (minWidth) {
        $target.css("max-width", `${minWidth}px`);
      }
    }

    function hide() {
      $el.hide();
      $target.removeClass("text-truncate");
      if (maxWidth) {
        $target.css("max-width", `${maxWidth}px`);
      }
    }

    $($el).on("click", hide);

    show();
  });
}
